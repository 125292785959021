import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../../assets/main_logo.svg";
import { Container, ImageContainer, LoginContainer } from "./styles";
import { api } from "../../services/api";
import { ICallPanel, IClinic, IOptions } from "../../models";
import { usePanel } from "../../contexts/PanelContext";

export default function Login() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [consultorioPanel, setConsultorioPanel] = React.useState<ICallPanel[]>([]);
  const [guichePanel, setGuichePanel] = React.useState<ICallPanel[]>([]);
  const [guicheSelectedPanel, setGuicheSelectedPanel] = React.useState<ICallPanel | null>(null);
  const [consultorioSelectedPanel, setConsultorioSelectedPanel] = React.useState<ICallPanel | null>(null);
  const [allClinics, setAllClinics] = React.useState<IClinic[]>([]);
  const [clinicId, setClinicId] = React.useState<string>("");
  const [clinicIdError, setClinicIdError] = React.useState<string>("");
  const [unification, setUnification] = React.useState<boolean>(true);
  const [typePanel, setTypePanel] = React.useState<IOptions>({ label: "Guichê", value: "guiche" });

  const navigate = useNavigate();
  const { handleRegisterPanel, handleRegisterPanelConsultorio } = usePanel();

  const getAllPanel = async (idclinica: string) => {
    try {
      setIsLoading(true);
      const consultorio = await api.get(
        `/users-patients/call-panel?idclinica=${idclinica}&type=CONSULTÓRIO`
      );
      const guiche = await api.get(
        `/users-patients/call-panel?idclinica=${idclinica}&type=GUICHÊ`
      );
      setGuichePanel(guiche.data.data.call_panels)
      setConsultorioPanel(consultorio.data.data.call_panels)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getAllClinics = async () => {
    try {
      setIsLoading(true);
      const res = await api.get("/call-panel/clinics");
      setAllClinics(res.data.data.clinics);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (clinicId !== "") {
      getAllPanel(clinicId);
    }
  }, [clinicId,]);

  useEffect(() => {
    getAllClinics();
  }, []);

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (consultorioSelectedPanel) {
        const res = await handleRegisterPanelConsultorio(consultorioSelectedPanel);
      }
      if (guicheSelectedPanel) {
        const res = await handleRegisterPanel(guicheSelectedPanel);
      }
      document.body.requestFullscreen();
      localStorage.setItem("clinica", JSON.stringify({...allClinics.find(e => e.idclinica === clinicId)}));
      navigate("/", {
        replace: true,
      });
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <Container>
      {isLoading && (
        <LinearProgress color="secondary" variant={"indeterminate"} />
      )}
      <ImageContainer>
        <Typography component="h1" sx={{
          fontWeight: 600,
          color: theme => theme.palette.background.default,
        }}>Painel de chamada</Typography>
      </ImageContainer>

      <LoginContainer>
        <img src={logo} alt="Logo" />
        <form onSubmit={handleLogin}>
          <Grid
            container
            mt={3}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <FormControlLabel
                style={{
                  color: "black"
                }}
                value="start"
                control={<Checkbox onChange={() => {
                  setUnification(!unification)
                  setGuicheSelectedPanel(null)
                  setConsultorioSelectedPanel(null)
                }} checked={unification} />}
                label="Unificação de painel"
                labelPlacement="start"
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                loading={isLoading}
                id="clinics"
                options={allClinics}
                onChange={(event: any, newValue: IClinic | null) => {
                  setClinicId(newValue === null ? "" : newValue.idclinica);
                  setClinicIdError("");
                }}
                getOptionLabel={(e) => e.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma clínica"
                    error={!!clinicIdError}
                    helperText={clinicIdError}
                  />
                )}
              />
            </Grid>
            {!unification && <Grid item lg={12} xs={12}>
              <Autocomplete
                disablePortal
                fullWidth
                loading={isLoading}
                value={typePanel}
                id="tipo"
                options={[
                  { label: "Guichê", value: "guiche" },
                  { label: "Consultórios", value: "consultorio" }
                ]}
                //@ts-ignore
                onChange={(event: any, newValue: IOptions) => {
                  if (newValue) setTypePanel(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione um tipo"
                  />
                )}
              />
            </Grid>}
            {
              !unification && <>
                {
                  typePanel.value === "guiche" ?
                    <Grid item lg={12} xs={12}>
                      <Autocomplete
                        fullWidth
                        loading={isLoading}
                        disabled={clinicId === ""}
                        value={guicheSelectedPanel}
                        options={guichePanel}
                        onChange={(event: any, newValue: ICallPanel | null) => {
                          // setSelectedPanel(newValue);
                          setGuicheSelectedPanel(newValue)
                        }}
                        getOptionLabel={(e) =>
                          `${e.nome} ${e.setor && e.bloco
                            ? `- ${e.setor?.nome} - ${e.bloco?.nome}`
                            : ""
                          }`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Selecione um guichê" />
                        )}
                      />
                    </Grid>
                    :
                    <Grid item lg={12} xs={12}>
                      <Autocomplete
                        fullWidth
                        loading={isLoading}
                        disabled={clinicId === ""}
                        value={consultorioSelectedPanel}
                        options={consultorioPanel}
                        onChange={(event: any, newValue: ICallPanel | null) => {
                          // setSelectedPanel(newValue);
                          setConsultorioSelectedPanel(newValue)
                        }}
                        getOptionLabel={(e) =>
                          `${e.nome} ${e.setor && e.bloco
                            ? `- ${e.setor?.nome} - ${e.bloco?.nome}`
                            : ""
                          }`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Selecione um consultório" />
                        )}
                      />
                    </Grid>
                }
              </>
            }
            {
              unification && <>
                <Grid item lg={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    loading={isLoading}
                    disabled={clinicId === ""}
                    value={guicheSelectedPanel}
                    options={guichePanel}
                    onChange={(event: any, newValue: ICallPanel | null) => {
                      setGuicheSelectedPanel(newValue)
                    }}
                    getOptionLabel={(e) =>
                      `${e.nome} ${e.setor && e.bloco
                        ? `- ${e.setor?.nome} - ${e.bloco?.nome}`
                        : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione um guichê" />
                    )}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    loading={isLoading}
                    disabled={clinicId === ""}
                    value={consultorioSelectedPanel}
                    options={consultorioPanel}
                    onChange={(event: any, newValue: ICallPanel | null) => {
                      setConsultorioSelectedPanel(newValue)
                    }}
                    getOptionLabel={(e) =>
                      `${e.nome} ${e.setor && e.bloco
                        ? `- ${e.setor?.nome} - ${e.bloco?.nome}`
                        : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione um consultório" />
                    )}
                  />
                </Grid>
              </>
            }
          </Grid>
          <Grid
            container
            mt={3}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <Button
                type="submit"
                disabled={guicheSelectedPanel === null && consultorioSelectedPanel === null}
                fullWidth
                size="large"
                variant="contained"
              >
                Entrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoginContainer>
    </Container>
  );
}
