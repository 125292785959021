import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ICallPanel } from "../models";
import { api } from "../services/api";

interface IPanelContext {
  isLoading: boolean;
  isRegistered: () => boolean;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setIsConnected: (value: boolean) => void;
  isConnected: boolean;
  panel: ICallPanel | null;
  panelConsultorio: ICallPanel | null;
  handleRegisterPanel: (panel: ICallPanel | null) => Promise<boolean>;
  handleRegisterPanelConsultorio: (panel: ICallPanel | null) => Promise<boolean>;
  handleClosePanel: () => Promise<void>;
}

interface IPanelProvider {
  children: ReactNode;
}

const TIME_LIMIT = 120;
const TIME_INITIAL = 1;

export const PanelContext = createContext<IPanelContext>({} as IPanelContext);

export const PanelProvider: React.FC<IPanelProvider> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [panel, setPanel] = useState<ICallPanel | null>(null);
  const [panelConsultorio, setPanelConsultorio] = useState<ICallPanel | null>(null);
  const [clock, setClock] = useState<number>(TIME_INITIAL);

  const handleRegisterPanel = async (panel: ICallPanel | null) => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        setIsLoading(true);

        localStorage.setItem("panel@drclick", JSON.stringify(panel));
        await getPanel();
        setIsOpen(true);
        resolve(true);
      } catch (error: any) {
        setIsLoading(false);
        if (error.response) {
          reject(error.response.data.error.message);
        }
      }
    });
  };
  const handleRegisterPanelConsultorio = async (panel: ICallPanel | null) => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        setIsLoading(true);
        localStorage.setItem("panel@drclickConsultorio", JSON.stringify(panel));
        await getPanelConsultorio();
        setIsOpen(true);
        resolve(true);
      } catch (error: any) {
        setIsLoading(false);
        if (error.response) {
          reject(error.response.data.error.message);
        }
      }
    });
  };

  const handleClosePanel = async () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        setIsLoading(true);

        localStorage.removeItem("panel@drclick");
        localStorage.removeItem("panel@drclickConsultorio");
        window.location.reload();
        resolve();
      } catch (error: any) {
        setIsLoading(false);
      }
    });
  };

  const getPanel = async () => {
    setIsLoading(true);
    const panelStorage = localStorage.getItem("panel@drclick");
    if (panelStorage !== null) {
      try {
        const res = await api.get(
          `/users-patients/call-panel-by-id?idpainelchamado=${
            JSON.parse(panelStorage).idpainelchamado
          }`
        );
        setPanel(res.data.data);
        setIsLoading(false);
        setIsConnected(true);
      } catch (error: any) {
        if (error.response.data.status === 404) {
          localStorage.clear();
        }
        setIsLoading(false);
        setIsConnected(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const getPanelConsultorio = async () => {
    setIsLoading(true);
    const panelStorage = localStorage.getItem("panel@drclickConsultorio");
    if (panelStorage !== null) {
      try {
        const res = await api.get(
          `/users-patients/call-panel-by-id?idpainelchamado=${
            JSON.parse(panelStorage).idpainelchamado
          }`
        );
        setPanelConsultorio(res.data.data)
        setIsLoading(false);
        setIsConnected(true);
      } catch (error: any) {
        if (error.response.data.status === 404) {
          localStorage.clear();
        }
        setIsLoading(false);
        setIsConnected(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const validateConnection = async () => {
    const panelStorage = localStorage.getItem("panel@drclick");
    if (panelStorage !== null) {
      try {
        await api.get(
          `/users-patients/call-panel-by-id?idpainelchamado=${
            JSON.parse(panelStorage).idpainelchamado
          }`
        );
        setIsConnected(true);
        window.location.reload();
      } catch (error: any) {
        if (error.response.data.status === 404) {
          localStorage.clear();
        }
        setIsConnected(false);
      }
    } else {
      setIsConnected(false);
    }
  };

  const isRegistered = () => {
    const totemStorage = localStorage.getItem("panel@drclick");
    const totemStorageConsultorio = localStorage.getItem("panel@drclickConsultorio");
    return (totemStorage === null && totemStorageConsultorio === null) ? false : true;
  };

  useEffect(() => {
    getPanel();
    getPanelConsultorio()
  }, []);

  useEffect(() => {
    let time: any = null;
    if (panel) {
      if (clock < TIME_LIMIT) {
        time = setInterval(() => {
          setClock((state) => state + 1);
        }, 1000);
      } else {
        setClock(TIME_INITIAL);
        validateConnection();
      }

      return () => clearInterval(time);
    }
  }, [clock, panel]);

  return (
    <PanelContext.Provider
      value={{
        isLoading,
        panel,
        panelConsultorio,
        isOpen,
        setIsOpen,
        isConnected,
        setIsConnected,
        isRegistered,
        handleRegisterPanel,
        handleRegisterPanelConsultorio,
        handleClosePanel,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

export const usePanel = () => {
  return useContext(PanelContext);
};
