import { Box, Grid, Typography, useTheme, Tooltip, IconButton } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import Logo from "../../assets/main_logo.svg";
import LogoWhite from "../../assets/logo-white.png";
import { api, socket } from "../../services/api";
import Logout from "../../assets/logout.svg";
// flag configs
import { usePanel } from "../../contexts/PanelContext";
import {
  ICallPanel,
  ICallPanelOffice,
  ICallPanelsOffice,
  IServicePassword,
  IServicePasswordPanel,
  IUnification,
  PatientOffice,
} from "../../models";
import CallModal from "./components/CallModal";
import CallPatientModal from "../OfficePanel/components/CallPatientModal";
import "./styles"
const TIME_LIMIT = 7;
const TIME_INITIAL = 1;
enum TypeName {
  guiche = 'guichê',
  consultorio = 'consultório',
}
interface ILastCall {
  call: string;
  local: string;
}
function NewPanel() {
  const theme = useTheme();
  const { panel, isOpen, panelConsultorio, handleClosePanel } = usePanel();
  const [servicePasswords, setServicePasswords] = useState<IServicePassword[]>(
    []
  );
  const [currentServicePassword, setCurrentServicePassword] =
    useState<IServicePassword | null>(null);
  const [panelsList, setPanelsList] = useState<IServicePasswordPanel[]>([]);
  const [clock, setClock] = useState<number>(TIME_INITIAL);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexOffice, setCurrentIndexOffice] = useState(0);
  const [historyPatients, setHistoryPatients] = useState<PatientOffice[]>([]);
  const [panelsOffice, setPanelsOffice] = useState<ICallPanelsOffice[]>([]);
  const [currentPatient, setCurrentPatient] = useState<string>("");
  const [currentProfessional, setCurrentProfessional] = useState<string>("");
  const [currentPrioritario, setCurrentPrioritario] = useState<boolean>(false);
  const [currentPanelOffice, setCurrentPanelOffice] = useState<ICallPanelOffice | null>(null);
  const [linkVideo, setLinkVideo] = useState<string>("");
  const msg = useMemo(() => new SpeechSynthesisUtterance(), []);

  function capitalizarPrimeiraLetra(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function unificationHistory(ServicePasswords: IServicePassword[], historyPatients: PatientOffice[]): IUnification[] {
    const unification = [...ServicePasswords, ...historyPatients]
    unification.sort((a, b) => {
      const dataA = new Date(a?.datachamada || a.dataatualizacao).getTime();
      const dataB = new Date(b?.datachamada || b.dataatualizacao).getTime();
      return dataB - dataA;
    });
    return unification as IUnification[]
  }

  function verifyNameGuiche(name: string | undefined) {
    if (!name) return false
    name = name.toLowerCase().replace(/guiche|guichê/g, '');
    return name;
  }

  function verifyNameConsultorio(name: string | undefined) {
    if (!name) return false
    name = name.toLowerCase().replace(/guiche|guichê|sala/g, '');
    return name;
  }

  function lastCall() {
    const last: IUnification[] = unificationHistory(servicePasswords, historyPatients)
    if (last.length > 0) {
      return {
        call: last[0].paciente?.nomesocial || last[0].paciente?.nomepaciente || last[0]?.senha,
        professional: last[0]?.profissional?.nomecomercial || "",
        local: verifyNameConsultorio(last[0]?.consultorio?.nome) || verifyNameGuiche(last[0]?.guiche?.nome || "aguardando"),
        consultorio: last[0]?.consultorio
      }
    }
  }
  useEffect(() => {
    const clinicStorage = localStorage.getItem("clinica");
    if (clinicStorage) {
      const clinic = JSON.parse(clinicStorage)
      if (clinic.url_video_painel) {
        setLinkVideo(clinic.url_video_painel as string)
      }
    }
  }, [])

  const comercial = useMemo(() => {
    if (linkVideo) {
      return linkVideo.replace("https://youtu.be/", "").replace("https://www.youtube.com/live/", "")
    }
    return ""
  }, [linkVideo])

  useEffect(() => {
    socket.on("connect", () => {
      console.log("[IO] => connected");
    });
    socket.on(`nextPasswordByServer`, (data: any) => {
      setPanelsList((old) => [...old, data]);
    });
    socket.on(`panelOffice`, (data: any) => {
      setPanelsOffice((old) => [...old, data]);
    });
  }, [panel, panelConsultorio]);
  // Consultorio
  const getAllOfficePatients = async (panel: ICallPanel) => {
    try {
      const res = await api.get(
        `/users-patients/patient-office-bypanelid?idpainelchamado=${panelConsultorio?.idpainelchamado}`
      );

      setHistoryPatients(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    if (
      panelsOffice.length > 0 &&
      currentIndexOffice <= panelsOffice.length - 1 &&
      isOpen
    ) {
      setCurrentPanelOffice(
        panelsOffice[currentIndexOffice].panels.find(
          (item) => item.idpainelchamado === panelConsultorio?.idpainelchamado
        ) || null
      );
      setCurrentPatient(panelsOffice[currentIndexOffice].nomepaciente);
      setCurrentProfessional(panelsOffice[currentIndexOffice].nomeprofissional || "");
      setCurrentPrioritario(panelsOffice[currentIndexOffice].prioritario || false);
    } else {
      setCurrentPanelOffice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelsOffice, currentIndexOffice, panelConsultorio, isOpen]);

  useEffect(() => {
    if (currentPanelOffice !== null) {
      msg.text = "";
      msg.text = `Atenção! Nova chamada! ${currentPatient}`;
      msg.rate = 0.8;
      window.speechSynthesis.speak(msg);
    }
  }, [currentPanelOffice, currentPatient, msg]);

  useEffect(() => {
    if (panelConsultorio !== null && isOpen) {
      getAllOfficePatients(panelConsultorio);
    }
  }, [panelConsultorio, panelsOffice, isOpen]);

  // guiche
  const gatAllPasswords = async (panel: ICallPanel) => {
    try {
      const res = await api.get(
        `/users-patients/passwordsbygroups?idpainelchamado=${panel?.idpainelchamado}`
      );
      setServicePasswords(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (currentServicePassword === null && panel !== null && isOpen) {
      gatAllPasswords(panel);
    }
  }, [currentServicePassword, panel, isOpen]);

  useEffect(() => {
    if (
      panelsList.length > 0 &&
      currentIndex <= panelsList.length - 1 &&
      isOpen
    ) {
      const existsPanel = panelsList[currentIndex].panels.find(
        (item) => item.idpainel === panel?.idpainelchamado
      );

      if (existsPanel) {
        setCurrentServicePassword(panelsList[currentIndex].password);
      } else {
        setCurrentServicePassword(null);
      }
    } else {
      setCurrentServicePassword(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelsList, currentIndex, panel, isOpen]);

  // Timer de desativar popup para consulta e guiche
  useEffect(() => {
    let time: any = null;
    if (clock < TIME_LIMIT && (currentServicePassword !== null || currentPanelOffice !== null)) {
      time = setInterval(() => {
        setClock((state) => state + 1);
      }, 1000);
    } else {
      if (panelsList.length - 1 < currentIndex) {
        setCurrentIndex(0);
        setPanelsList([]);
      } else {
        setCurrentIndex((old) => old + 1);
      }
      if (panelsOffice.length - 1 < currentIndexOffice) {
        setCurrentIndexOffice(0);
        setPanelsOffice([]);
      } else {
        setCurrentIndexOffice((old) => old + 1);
      }

      setClock(TIME_INITIAL);
    }
    return () => clearInterval(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, currentServicePassword, panel, currentPanelOffice]);
  useEffect(() => {
    if (currentServicePassword) {
      msg.text = "";
      msg.text = `Atenção! Senha ${currentServicePassword?.senha}!`;
      window.speechSynthesis.speak(msg);
    }
  }, [currentServicePassword, msg]);

  return (
    <>
      {currentPanelOffice !== null && isOpen && (
        <CallPatientModal
          currentPanelOffice={currentPanelOffice}
          currentPatient={currentPatient}
          currentPrioritario={currentPrioritario}
          currentProfessional={currentProfessional}
        />
      )}
      {currentServicePassword !== null && isOpen && (
        <CallModal
          currentServicePassword={currentServicePassword}
          setCurrentServicePassword={setCurrentServicePassword}
        />
      )}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          position: "relative",
          marginBottom: "5rem",
          backgroundColor: "#148776"
        }}>
          <div style={{
            position: "absolute",
            left: "10px"
          }}>
            <Tooltip title="Fechar painel">
              <IconButton onClick={() => handleClosePanel()}>
                <img src={Logout} width={24} alt="" />
              </IconButton>
            </Tooltip>
          </div>
          {/* <div style={{
            position: "absolute",
            right: "10px"
          }}>
              <IconButton onClick={() => handleClosePanel()}>
                <img src={LogoWhite} width={130} alt="" />
              </IconButton>
          </div> */}
          <div style={{
            width: "60%",
            backgroundColor: "#fff",
            marginBottom: "-4rem",
            padding: "10px",
            marginTop: "1rem",
            marginRight: "1rem",
            border: "4px solid #29B099",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
          }}>
            <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", gap: "4rem", width: "100%" }}>
              <Grid container style={{position: "relative"}} flexDirection="column" alignItems="start" >
                <Grid>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "26px",
                      fontWeight: 600,
                      color: "#363636",
                    }}
                  >
                    Chamada
                  </Typography>
                  
                </Grid>
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: 0
                  }}>
                      <IconButton onClick={() => handleClosePanel()}>
                        <img src={Logo} width={100} alt="" />
                      </IconButton>
                  </div>
                <Grid>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "40px",
                      fontWeight: 700,
                      color: "#ff702a",
                      textAlign: "center",
                      whiteSpace: lastCall()?.consultorio ? "wrap" : "nowrap",
                    }}
                  >
                    {capitalizarPrimeiraLetra(lastCall()?.call || "")}
                  </Typography>
                </Grid>
              </Grid>
              {lastCall()?.professional && <Grid container flexDirection="column" alignItems="center" >
                <Grid>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "26px",
                      fontWeight: 600,
                      color: "#363636",
                    }}
                  >
                    Profissional
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "40px",
                      fontWeight: 700,
                      color: "#ff702a",
                      textAlign: "center",
                      whiteSpace: lastCall()?.consultorio ? "wrap" : "nowrap",
                    }}
                  >
                    {capitalizarPrimeiraLetra(lastCall()?.professional || "")}
                  </Typography>
                </Grid>
              </Grid>}
            </div>
          </div>
          <div style={{
            backgroundColor: "#fff",
            marginBottom: "-4rem",
            padding: "10px",
            marginTop: "1rem",
            border: "4px solid #29B099",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "1rem",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}>
            <div style={{ display: "flex", alignItems: "start", gap: "4rem" }}>
              <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                <Grid >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: 600,
                      color: "#363636",
                    }}
                  >
                    {lastCall()?.consultorio?.nome ? "Consultório" : "Guichê"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      fontSize: "40px",
                      fontWeight: 700,
                      color: "#ff702a",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {lastCall()?.local}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div style={{
              width: "100%",
              height: 5,
              backgroundColor: "#148776"
            }}/> 
        {!isOpen ? (
          <Grid
            container
            sx={{
              pl: 2,
            }}
          >
            <Grid item lg={12} xs={12} display="flex" justifyContent="center">
              <Typography
                component="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: 600,
                  color: theme.palette.secondary.light,
                  textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                }}
              >
                ATENÇÃO! PAINEL FECHADO
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
          >
            {comercial && <Grid item lg={6} xs={6} pt={2}>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  mt={1}
                >
                   <iframe
                    style={{ borderRadius: "10px" }}
                    width="680"
                    height="405"
                    src={`https://www.youtube.com/embed/${comercial}&autoplay=1&loop=1&controls=1`}
                    title="Video comercial"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </Grid>
                <Grid xs={12} mt={2} item display="flex"
                  justifyContent="center">
                  <img src={Logo} style={{ width:"30%"}} alt="" />
                </Grid>
              </Grid> 
            </Grid>}
            <Grid item lg={comercial ? 6 : 12} xs={12} pt={2} pl={comercial ? 0 : 2} pr={comercial ? 0 : 2} display="flex" alignItems="start">
              <Grid
                container
                sx={{
                  backgroundColor: theme.palette.background.default,
                  overflowY: "scroll",
                }}
              >
                <Grid item lg={12}>
                  <Grid container maxHeight="100%">
                    <Grid
                      item
                      lg={7}
                      xl={7}
                      sx={{
                        p: 1,
                        paddingLeft: "1.5rem",
                        alignItems: "center",
                        justifyContent: "start",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "26px",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Chamada
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      xl={5}
                      sx={{
                        p: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "26px",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Local
                      </Typography>
                    </Grid>
                    {servicePasswords.length === 0 && historyPatients.length === 0 ? (
                      <Grid
                        item
                        lg={12}
                        sx={{
                          p: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2rem",
                          display: "flex",
                        }}
                      >
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "28px",
                            fontWeight: 600,
                            color: "#fff",
                          }}
                        >
                          Nenhuma senha encontrada
                        </Typography>
                      </Grid>
                    ) : (
                      unificationHistory(servicePasswords, historyPatients).map((item, index: number) => (
                        <Fragment key={item.idsenhaatendimento}>
                          <Grid
                            item
                            lg={7}
                            md={7}
                            sm={7}
                            xs={7}
                            sx={{
                              p: 1,
                              margin: "0.5rem 0",
                              alignItems: "center",
                              display: "flex",
                              textAlign: "start",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              backgroundColor: theme.palette.primary.dark
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: verifyNameConsultorio(item?.consultorio?.nome) ? "2rem" : "2.5rem",
                                fontWeight: 700,
                                color: "#fff",
                                marginLeft: '10px',
                              }}
                            >
                              {verifyNameConsultorio(item?.consultorio?.nome) ? capitalizarPrimeiraLetra(item.paciente?.nomesocial || item.paciente?.nomepaciente || item?.senha || "") : (item.paciente?.nomesocial || item.paciente?.nomepaciente || item?.senha || "")?.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={5}
                            sx={{
                              p: 1,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0.5rem 0",
                              backgroundColor: theme.palette.primary.dark,
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              {item?.consultorio?.nome ? "Consultório" : "Guichê"}
                            </Typography>
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: verifyNameConsultorio(item?.consultorio?.nome) ? "2.8rem" : "2.8rem",
                                fontWeight: 700,
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              {verifyNameConsultorio(item?.consultorio?.nome) || verifyNameGuiche(item?.guiche?.nome) || "Aguardando"}
                            </Typography>
                          </Grid>
                        </Fragment>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default NewPanel;
